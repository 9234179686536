<template>
  <div>
    <div
      class="imgplayer2"
      style="background: url(img/dongtai.jpg) no-repeat center;"
    ></div>
    <div class="c_big">
      <div class="c_left">
        <div class="l_top">企业文化</div>
        <ul class="dongtai">
          <a href="javascript:void(0)">
            <li :class="{ l_hot: current === 0 }" @click="current = 0">
              文化理念
            </li>
          </a>
          <a href="javascript:void(0)">
            <li
              :class="{ l_hot: current === 1 }"
              @click="(current = 1), (currentPage = 1)"
            >
              水务新闻
            </li>
          </a>
          <a href="javascript:void(0)">
            <li :class="{ l_hot: current === 2 }" @click="current = 2">
              党建工作
            </li>
          </a>
          <a href="javascript:void(0)">
            <li :class="{ l_hot: current === 3 }" @click="current = 3">
              节水宣传
            </li>
          </a>
          <a href="javascript:void(0)">
            <li :class="{ l_hot: current === 4 }" @click="current = 4">
              群团工作
            </li>
          </a>
          <a href="javascript:void(0)">
            <li :class="{ l_hot: current === 5 }" @click="current = 5">
              纪检监察
            </li>
          </a>
        </ul>
      </div>
      <div class="c_right" v-show="current === 0">
        <div class="r_top">
          <span id="r_bt">文化理念</span
          ><span id="map">当前位置：企业文化 &gt; 文化理念</span>
        </div>
        <div class="r_txts">
          <h1>衡东县自来水有限公司文化理念</h1>
          <!-- <div v-if="waterPromise&&waterPromise[0]" v-html="waterPromise&&waterPromise[0]?aterPromise[0].text:''"></div> -->
          <!-- <el-empty v-else description="暂无数据"></el-empty> -->
        </div>
        <div>
          <p class="h2_border">
            核心价值观：优质供水 服务于民
          </p>
          <p class="h2_border">
            服务理念：安全优质供水 用心服务社会
          </p>
          <p class="h2_border">
            战略目标：全力打造科技智慧水务
          </p>
        </div>
      </div>
      <div class="c_right" v-show="current === 1">
        <div class="r_top">
          <span id="r_bt">新闻</span
          ><span id="map">当前位置：企业文化 &gt; 水务新闻</span>
        </div>
        <div class="r_txt" >
          <ul v-if="news.length" class="div_daxiao">
            <li v-for="(item, index) in news" :key="index">
              <a style="display:flex" target="_black" :href="item.url">
                <span style="flex:1">•&nbsp; {{ item.title }}</span>
                <span id="time">{{ item.createTime }}</span>
              </a>
            </li>
          </ul>
          <!-- <ul v-if="SwNews1.length">
            <li v-for="(item,index) in SwNews1" :key="index">
              <span id="news_nav" @click="openUrl(item)">•&nbsp; {{ item.title }}</span>
              <span id="time">{{ item.update_time | Date }}</span>
            </li>
          </ul> -->
          <el-empty v-else description="暂无数据"></el-empty>
        </div>
        <!--          分页功能-->
        <div v-if="SwNews1.length" class="flex" style="margin-bottom: 10px">
          <el-button size="mini" @click="gotoFirst()">首页</el-button>
          <el-pagination
            background
            :page-size="15"
            :current-page="currentPage"
            layout="prev, pager, next"
            :total="SwNews.length"
            @current-change="change"
            prev-text="上一页"
            next-text="下一页"
          >
          </el-pagination>
          <el-button size="mini" @click="gotoEnd(SwNews)">末页</el-button>
        </div>
      </div>
      <div class="c_right" v-show="current === 2">
        <div class="r_top">
          <span id="r_bt">党建工作</span
          ><span id="map">当前位置：企业文化 &gt; 党建工作</span>
        </div>
        <div class="r_txt">
          <h1 style="text-align:center;">{{ title }}</h1>
          <div style="line-height:36px;text-indent:2rem" v-if="selected === 0">
            <p>
              2021年,衡东县自来水有限公司坚持“聚精会神抓党建、一心一意谋发展”的总体思路,进一步加强和创新基层党建工作,为实现公司持续快速健康发展提供坚强的政治保证、组织保证和思想保证。
            </p>
            <p>
              一是党建主体责任更加明确。公司始终坚持把党的全面领导、加强党的建设作为企业的“根”和“魂”,把党建工作摆在更加重要的突出位置,认真制定了年度党建工作要点及任务清单,建立完善了党建工作制度,形成一套落实有力、运转灵活、制度健全的党建工作机制。
            </p>
            <p>
              二是党建服务水平明显提升。公司精心设计改造了党员活动室,更新了党建文化墙,增加了党建元素,营造了党建氛围。改造升级后的党员活动室为加强对党员教育培训,开展各项组织活动提供了平台,增强了党员的归属感、凝聚力和向心力。
            </p>
            <p>
              三是党的组织活动更加规范。进一步规范支部组织活动形式和内容,规范开展支部“主题党日”、“三会一课”、组织生活会、民主评议党员等活动,使党的组织生活真正严起来、实起来。
            </p>
            <p>
              四是党员先锋模范作用更加突出。公司党支部组织全体在职党员深入洣江社区开展了进社区志愿服务活动,促使党员创先争优热情空前高涨,先锋模范作用得到充分发挥。
            </p>
          </div>
          <div style="line-height:36px;text-indent:2rem" v-if="selected === 1">
            <h2>党建促服务</h2>
            <p>
              今年来，衡东县自来水有限公司注重将党建引领与优化营商环境两者有机结合起来，充分发挥党组织核心领导作用、支部战斗堡垒作用和党员先锋模范作用，围绕服务抓党建，抓好党建促服务，全力打造办事效率最好、服务水平最优、营商环境最佳的“供水服务”品牌。
            </p>
            <h2>狠抓学习强本领</h2>
            <p>
              公司坚持把优化营商环境作为支部“三会一课”、组织生活会、主题党日等组织生活制度的重要内容，坚持定期和不定期组织党员参加集中学习会、线上培训及外出学习考察等方式，加强政策和业务培训，持续为思想“充电”，为精神“补钙”，确保每个党员紧跟方向“不跑偏”，执行政策“不走样”，不断增强党建引领营商环境建设的针对性和实效性。
            </p>
          </div>
          <div style="line-height:36px;text-indent:2rem" v-if="selected === 2">
            <p>
              3月16日下午3时,衡东县自来水有限公司召开党总支班子的专题组织生活会,会议由党支部书记文桂年主持,水务投副总经理颜学文、纪检组长刘灿和综合部部长向正根应邀参加了会议。
            </p>
            <img src="img/hengdong/p1.jpg" alt="" />
            <p>
              会上,文桂年同志先代表党支部班子进行对照检查,随后支委成员逐个做简要对照检查,开展批评与自我批评。此次组织生活会成效明显,每个成员都在自己身上找到了3条以上的问题,互相之间批评也找了2条以上。最后,颜学文同志对本次组织生活会进行了点评,他对此次会议进行了充分的肯定,赞扬此次组织生活会上,班子成员之间直言不讳,问题都抓在了点上。接下来就是要根据找出的问题,做出整改方案,并严格执行。同时他又对公司党支部班子提出以下要求：第一,继续优质供水经济建设；第二,坚持不懈的做好民生工作、为民服务;第三,提高党总支的决策能力和执行能力;第四,要总结好现阶段党史学习教育的经验,为下一步任务做指导。
            </p>
          </div>
          <ul class="div_daxiao" >
            <li v-for="(item, index) in dangjianList" :key="index">
              <a style="display:flex" target="_black" :href="item.url">
                <span style="flex:1">•&nbsp; {{ item.title }}</span>
                <span id="time">{{ item.createTime }}</span>
              </a>
            </li>
            <li
              style="display:flex"
              :style="{ fontWeight: selected === 2 ? 'bold' : '' }"
              @click="selected = 2"
            >
            <div style="display:flex">
              <div>
                <a href="javascript:void(0)">•&nbsp; 县自来水有限公司党支部组织召开2021年度组织生活会</a>
              </div>
              <div style="position: absolute;right: 355px;">2022-03-16 15:06</div>
            </div>
              
            </li>
            <li
              style="display:flex"
              :style="{ fontWeight: selected === 1 ? 'bold' : '' }"
              @click="selected = 1"
            >
            <div style="display:flex">
              <div>
                <a href="javascript:void(0)">•&nbsp; 以党建促服务 优化营商环境</a>
              </div>
              <div style="position: absolute;right: 355px;">2022-05-16 14:00</div>
            </div>
              
            </li>
            <li
              style="display:flex"
              :style="{ fontWeight: selected === 0 ? 'bold' : '' }"
              @click="selected = 0"
            >
            <div style="display:flex">
              <div>
                <a href="javascript:void(0)">•&nbsp; 县自来水有限公司党建工作呈现四大亮点</a>
              </div>
              <div style="position: absolute;right: 355px;">2021-03-16 10:00</div>
            </div>
              
            </li>
            
            
            
            <!-- <li>
              <a href="https://mp.weixin.qq.com/s/2OXXyio0i8LFCqXneKTLVw" target="_blank"
                >•&nbsp; 衡东县自来水有限公司开展“金秋五课”学习培训</a
              >
            </li>
            <li>
              <a href="https://mp.weixin.qq.com/s/NlCFiM-fABFx37d5I2Momg" target="_blank"
                >•&nbsp; 联播+丨一图看懂二十大报告中这些关键词</a
              >
            </li> -->
          </ul>
        </div>
      </div>
      <div class="c_right" v-show="current === 3">
        <div class="r_top">
          <span id="r_bt">节水宣传</span
          ><span id="map">当前位置：企业文化 &gt; 节水宣传</span>
        </div>
        <div class="r_txt">
          <div v-html="content"></div>
          <ul class="div_daxiao">
            <li
              v-for="item in waterSaving"
              :key="item.id"
              :style="{ fontWeight: selected === item.id ? 'bold' : '' }"
              @click="showContent(item)"
            >
              <a style="display:flex" href="javascript:void(0)">
                <span style="flex:1">•&nbsp; {{ item.title }} </span>
                <span id="time">{{ item.createTime }}</span>
              </a>
            </li>
          </ul>
          <!-- <h1 style="text-align:center;">【世界水日】衡东供水志愿服务活动 倡导节水理念</h1>
          <div  style="line-height:36px;text-indent:2rem">
            <p>2022年3月22日是第三十届“世界水日”，3月22—28日是第三十五届“中国水周”。联合国确定2022年“世界水日”主题为“Groundwater-Making the Invisible Visible”（珍惜地下水，珍视隐藏的资源）。我国纪念2022年“世界水日”“中国水周”活动主题为“推进地下水超采综合治理 复苏河湖生态环境”。</p>
            <p>今天上午，衡东县自来水有限公司在县文体广场开展“帅乡新风——新时代文明实践在衡东”“衡阳群众”志愿服务活动。</p>
            <img src="img/hengdong/p2.jpg" alt=""> 
            <p>现场为民解答取水、用水等方面的咨询，并向大家倡导节水理念，普及节水知识。</p>
            <img src="img/hengdong/p3.jpg" alt=""> 
            <img src="img/hengdong/p4.jpg" alt="">
            <img src="img/hengdong/p5.jpg" alt="">  
            <p>向过往居民发放供水知识宣传彩页，并针对小区居民反映的在用水过程中存在的跑冒滴漏、供水设施故障等问题逐条耐心解答，为居民解决实际用水问题。</p>
             <img src="img/hengdong/p6.jpg" alt="">  
            <p>同时，倡导大家从点滴做起，珍惜水资源，增强了广大居民爱水、护水、节水的意识。</p>
            <img src="img/hengdong/p7.jpg" alt="">  
            <p>此次活动解答市民咨询50余人次，发放宣传资料1000余份。</p>
          </div> -->
        </div>
      </div>
      <div class="c_right" v-show="current === 4">
        <div class="r_top">
          <span id="r_bt">群团工作</span
          ><span id="map">当前位置：企业文化 &gt; 群团工作</span>
        </div>
        <div class="r_txt">
          <ul v-if="groupWork.length" class="div_daxiao">
          <li
            style="line-height:36px"
            v-for="(item, index) in groupWork"
            :key="index"
          >
            <a style="display:flex" target="_black" :href="item.url">
              <span style="flex:1">•&nbsp; {{ item.title }}</span>
              <span id="time">{{ item.createTime }}</span>
            </a>
          </li>
        </ul>
        <el-empty v-else description="暂无数据"></el-empty>
        </div>
        
      </div>
      <div class="c_right" v-show="current === 5">
        <div class="r_top">
          <span id="r_bt">纪检监察</span
          ><span id="map">当前位置：企业文化 &gt; 纪检监察</span>
        </div>
        <div class="r_txt">
          <ul v-if="inspection.length" class="div_daxiao">
          <li
            style="line-height:36px"
            v-for="(item, index) in inspection"
            :key="index"
          >
            <a style="display:flex" target="_black" :href="item.url">
              <span style="flex:1">•&nbsp; {{ item.title }}</span>
              <span id="time">{{ item.createTime }}</span>
            </a>
          </li>
        </ul>
        <el-empty v-else description="暂无数据"></el-empty>
        </div>
        
      </div>
      <div style="clear: both;"></div>
    </div>
  </div>
</template>

<script>
//引入element组件库
import ElementUI from "element-ui";
// 引入http
import http from "../http/index";
import Vue from "vue";
Vue.use(ElementUI);
export default {
  data() {
    return {
      content: "",
      selected: 0,
      news: [],
      dangjianList:[],
      current: 0,
      currentPage: 1,
      // 水务新闻
      SwNews: [],
      waterPromise: [],
      inspection: [],
      groupWork: [],
      waterSaving: [],
    };
  },
  filters: {
    // 返回一个日期  年-月-日
    Date(val) {
      return val.slice(0, 10);
    },
  },
  methods: {
    showContent(obj) {
      if (obj.type === "text") {
        Vue.axios.get(obj.url).then((res) => {
          this.content = res;
        });
      }
      if (obj.type === "url") {
        window.open(obj.url);
      }
      this.selected = obj.id;
    },
    async getWaterSaving() {
      this.waterSaving = await http.getWaterSaving();
    },
    async getGroupWork() {
      this.groupWork = await http.getGroupWork();
    },
    async getInspection() {
      this.inspection = await http.getInspection();
    },
    async getNews() {
      this.news = await http.getNews();
    },
    async getdangjianList() {
      this.dangjianList = await http.getdangjianList();
    },
    // 获取水务新闻数据
    async getNewsMsg() {
      try {
        const res = await http.getNewsMsg();
        // console.log("水务news", res);
        this.SwNews = res.rows.sort(this.SortDate("update_time", false));
        // console.log(this.SwNews, "++++++++");
        // console.log(this.showid);
        // console.log(this.nowData)
      } catch (ex) {
        console.log(ex);
      }
    },

    async getpromise() {
      try {
        const res = await http.getpromise();
        // console.log("承诺", res);
        this.waterPromise = res;
        // this.waterAnnouncement = res.sort(this.SortDate("createtime",false))
        // this.SwNews = res.rows.sort(this.SortDate('update_time',false));
      } catch (ex) {
        console.log(ex);
      }
    },

    // 按时间进行排序算法
    SortDate(time, type) {
      //createtime
      return function(a, b) {
        let value1 = a[time];
        let value2 = b[time];
        if (type) {
          //升序排序
          return Date.parse(value1) - Date.parse(value2);
        } else {
          //降序
          return Date.parse(value2) - Date.parse(value1);
        }
      };
    },
    //分页切换
    change(val) {
      // console.log(`第${val}页`);
      this.currentPage = val;
    },
    // 回到首页
    gotoFirst() {
      this.currentPage = 1;
    },
    // 去到末页
    gotoEnd(list) {
      this.currentPage = Math.ceil(list.length / 15);
    },
    openUrl(list) {
      // console.log(list)
      // console.log(list.url)
      window.open(list.url);
    },
  },
  computed: {
    // 水务新闻分页
    title() {
      let n = this.selected;
      const titles = [
        "县自来水有限公司党建工作呈现四大亮点",
        "以党建促服务 优化营商环境",
        "县自来水有限公司党支部组织召开2021年度组织生活会",
      ];
      return titles[n];
    },
    SwNews1() {
      return this.SwNews.slice(
        (this.currentPage - 1) * 15,
        this.currentPage * 15
      );
    },
  },
  watch: {
    "$route.params.active": {
      handler(newVal, oldVal) {
        this.current = newVal ? parseInt(newVal) : parseInt(oldVal);
      },
    },
  },
  mounted() {
    let current = this.$route.params.active;
    this.current = current ? parseInt(current) : 0;
    console.log("this.current", this.current);
    this.getNewsMsg();
    this.getpromise();
    this.getNews();
    this.getdangjianList();
    this.getGroupWork();
    this.getInspection();
    this.getWaterSaving();
  },
};
</script>

<style>
.flex {
  display: flex;
}
#news_nav:hover {
  cursor: pointer;
  color: #064a93;
}
/* 分页样式修改 */
.el-pagination.is-background .el-pager li:not(.disabled).active {
  /* 修改背景颜色和字体颜色 */
  background-color: #064a93;
  color: #ffffff;
}

/* 鼠标悬浮样式修改 */
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  background-color: #064a93;
  color: white;
}

/* 首页按钮样式修改 */
.el-button {
  border: none;
  background-color: #f4f4f4;
}

/* 鼠标悬浮样式修改 */
.el-button:hover {
  color: white !important;
  /* border-color: #95cc83; */
  background-color: #064a93 !important;
}

.el-pagination span:hover {
  background-color: #064a93;
  color: #ffff;
}

/* 按钮点击后事件 */
.el-button:focus {
  color: rgb(97, 97, 97);
  border-color: #f4f4f4;
  background-color: #f4f4f4;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  width: 58px;
}
.el-pagination span:hover {
  width: 58px;
  border-radius: 2px;
}
.r_txts {
  color: #064a93;
  width: 800px;
  text-align: center;
  border-bottom: 1px solid #856d72;
}
.h2_border{
  padding-top: 30px;
  font-size: 20px;
  font-weight: 700;
}
/* 溢出滚动 */
.div_daxiao{
  height: 330px; 
  overflow: auto; 
  border: 1px solid #eee;
}
</style>
